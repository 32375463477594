import React from "react"
import Fade from "react-reveal/Fade"
import { faqData } from "../../data/faq"
import { Flex } from "@chakra-ui/react"

const Faq = () => {
  return (
    <Flex px={{ base: 5, sm: 35, lg: 60, xl: 80 }} marginBottom={32}>
    <div className="max-w-7xl mx-auto mt-10 text-center text-white" id="faq">
      <Fade bottom>
        <h1 className="text-5xl font-poppins font-bold xxs:text-xl sm:text-3xl lg:text-5xl">
          Frequently asked questions
        </h1>
      </Fade>
      <Fade bottom cascade>
        <div className="grid grid-cols-2 gap-8  text-left mt-10 xxs:grid-cols-1 sm:grid-cols-2">
          {faqData.map(faq => (
            <div className="xxs:p-2 sm:p-0">
              <h1 className="font-bold text-2xl font-poppins xxs:text-lg sm:text-2xl">{ faq.title }</h1>
              <p className="mt-2 text-sm opacity-75">{ faq.description } {" "} {faq.url &&( <a href={faq.url} className="hover:text-cyan-200">{faq.url}</a>) }</p>
            </div>
          ))}
        </div>
      </Fade>
      </div>
    </Flex>
  )
}

export default Faq
