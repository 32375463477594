import React from "react"
import Fade from "react-reveal/Fade"
import { tutorials } from "../../data/tutorials"

const Tutorials = () => {
  
  return (
    <div className="max-w-7xl mx-auto mt-10 text-white mb-10" id="tutorials">
      <Fade bottom cascade>
      <div className="w-full h-72 mt-10 rounded-xl flex flex-col items-center justify-center">
        <h1 className="font-poppins font-bold text-3xl">Check Out Our Video Tutorials</h1>
        <h2 className="text-lg mt-2 opacity-50">
          These tutorials will help you to configure the software regarding to your needs.
        </h2>
      </div>
        <div className="grid grid-cols-3 gap-4 xxs:grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
          {tutorials.map(tutorial => (
            <div>
              <div className="w-96 h-72 overflow-hidden rounded-xl xxs:w-full sm:w-96">
                <iframe width="100%" height="100%" src={tutorial.url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true"></iframe>
              </div>
              <h1 className="text-3xl mt-2 font-poppins font-bold">
                {tutorial.heading}
              </h1>
              <p className="text-sm opacity-50 mt-2">{tutorial.para}</p>
            </div>
          ))}
        </div>
      </Fade>
    </div>
  )
}

export default Tutorials
