import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { ListDevices } from "../components/compatible-devices"

const CompatibleDevices = () => {
    return (
        <>
            <Seo
                title={"Compatible Devices"}
                description={"Compatible devices: Check the product's specifications for a list of supported devices."}
            />

            <Layout>

                <ListDevices />
            </Layout>
        </>
    )
}

export default CompatibleDevices
