import React from "react"
import Fade from "react-reveal/Fade"

import {
  chakra,
  Box,
  Flex,
  useColorModeValue,
} from "@chakra-ui/react";
import Banner from "./../../assets/img/banner.png"

const Header = () => {
  return (
    <>
    <div className="max-w-7xl mx-auto px-8">
      <Fade>
        <div>
          <h2 className="text-white font-poppins opacity-50 font-semibold mt-20 text-xs xxs:text-xs xs:text-xs sm:text-xs md:text-sm lg:text-xl">
            We deliver the ultimate
          </h2>

          <h1 className="text-white font-poppins font-semibold text-xl xxs:text-2xl xs:text-2xl sm:text-lg md:text-2xl lg:text-4xl xl:text-7xl 2xl:text-6xl">
          <span className="text-gradient bg-gradient-to-r from-orange-300 to-red-400">Software</span> <br />to Stream & Manage <br /> {" "}
            <span className="text-gradient bg-gradient-to-r from-orange-300 to-red-400">
            your own Content.
            </span>
          </h1>
        </div>
      </Fade>
      <div className="max-w-7xl mx-auto mt-10">
        <div className="w-full h-auto overflow-hidden rounded-xl">
          <img className="w-full h-50" src={Banner} alt="Header"></img>
        </div>
      </div>
      </div>
      <Flex
      p={50}
      w="full"
      alignItems="center"
      justifyContent="center"
    >
      <Box>
        <Box
          maxW="7xl"
          w={{ md: "3xl", lg: "6xl" }}
          mx="auto"
          py={{ base: 10, lg: 14 }}
          px={{ base: 4, lg: 8 }}
          display={{ lg: "flex" }}
          alignItems={{ base: "center" }}
          justifyContent={{ lg: "space-between" }}
        >
          <chakra.h2
            fontSize={{ base: "3xl", sm: "4xl" }}
            fontWeight="extrabold"
            letterSpacing="tight"
              lineHeight="shorter"
              textAlign="justify"
            color={useColorModeValue("gray.100")}
            >
              <chakra.h3 display="block" mb={3} className="text-gradient bg-gradient-to-r from-orange-300 to-red-600 text-center">
                IMPORTANT NOTICE! <br />
                1-Stream is a <chakra.span className="text-gradient bg-gradient-to-r from-green-400 to-green-600 text-center"><a href="https://en.wikipedia.org/wiki/Standalone_program" target="_blank" className="text-bold">standalone software</a></chakra.span> ONLY! We DO NOT provide or sell ANY CONTENT!
              </chakra.h3>

              <hr />
              <br />

            <chakra.span display="block" mb={3} className="text-gradient bg-gradient-to-r from-pink to-purple">About Our Software</chakra.span>
            <chakra.span
                display="block"
                fontSize={{ base: "xl", sm: "xl" }}
                color={"gray.200"}
                fontWeight={'normal'}
              >
                1-Stream software is a standalone streaming software that allows users to stream their services.
                Through our installation guide, you can easily set up the software on your server and start using it.
                Before installing it into your server you have to bear in mind there are hardware restrictions and minimum requirements for our software to work as it should.
              </chakra.span>

              <chakra.span display="block" mt={6} mb={3} className="text-gradient bg-gradient-to-r from-pink to-purple">R&D</chakra.span>
            <chakra.span
                display="block"
                fontSize={{ base: "xl", sm: "xl" }}
                color={"gray.200"}
                fontWeight={'normal'}
              >
                For more than 2 years of Research & Development team has achieved almost perfect software in terms of UX/UI and functionality.
                Our software combines the latest technologies to provide a smooth experience and support many devices on the market with less configuration effort.
              </chakra.span>

              <chakra.span display="block" mt={6} mb={3} className="text-gradient bg-gradient-to-r from-pink to-pinpurplek">Security & Copyright</chakra.span>
            <chakra.span
                display="block"
                fontSize={{ base: "xl", sm: "xl" }}
                color={"gray.200"}
                fontWeight={'normal'}
              >
              The core engine of our software makes it robust against vulnerable parts because our software uses the latest encryption technologies & Video Encrypting Architecture (DRM) protecting against unwanted harms.              </chakra.span>
          </chakra.h2>
        </Box>
      </Box>
    </Flex>
    </>
  )
}

export default Header
