export const tutorials = [
  {
    url: "https://www.youtube.com/embed/rhnS4B__Yxw",
    heading: "How to create a line.",
  },
  {
    url: "https://www.youtube.com/embed/z2egSQFUgaE",
    heading: "How to add a user.",
  },
  {
    url: "https://www.youtube.com/embed/l026QFqegV8",
    heading: "How to addd a MAG Device.",
  },
  {
    url: "https://www.youtube.com/embed/EGmk4laLZQE",
    heading: "How to add a Server/LB.",
  },
  {
    url: "https://www.youtube.com/embed/E5IcCqOibr8",
    heading: "How to add proxy technology.",
  },
  {
    url: "https://www.youtube.com/embed/fjj-GrxHr_4",
    heading: "How to add package.",
  },
]
