import React from "react"
import {
  chakra,
  Box,
  useColorModeValue,
  Stack,
  Image,
  Flex,
} from "@chakra-ui/react"

import SupportImage from "../../assets/img/support.gif"

const Hero = () => {
  return (
    <Flex
      direction={{ base: "column", md: "row" }}
      bg={useColorModeValue("brand.500")}
      px={{ base: 5, sm: 35, lg: 60, xl: 80 }}
      py={24}
      mx="auto"
    >
      <Box
        w={{ base: "full", md: 11 / 12, xl: 9 / 12 }}
        mx="auto"
        pr={{ md: 20 }}
        my="auto"
      >
        <chakra.h2
          fontSize={{ base: "3xl", sm: "4xl" }}
          fontWeight="extrabold"
          lineHeight="shorter"
          color={useColorModeValue("white", "gray.100")}
          mb={6}
        >
          <chakra.span display="block">Do you need help?</chakra.span>
          <chakra.span
            display="block"
            color={useColorModeValue("white", "gray.500")}
          >
            Contact our{" "}
            <span class="text-gradient bg-gradient-to-r from-pink to-purple">
              Support Team 24/7!
            </span>
          </chakra.span>
        </chakra.h2>
        <chakra.p mb={6} fontSize={{ base: "lg", md: "xl" }} color={"white"}>
          We are here to help you with any of your needs.
        </chakra.p>
        <Stack
          direction={{ base: "column", sm: "row" }}
          mb={{ base: 4, md: 8 }}
          spacing={2}
        >
          <Box display="inline-flex" rounded="md" shadow="md">
            <chakra.a
              w="full"
              display="flex"
              alignItems="center"
              justifyContent="center"
              border="solid 1px transparent"
              fontSize={{ base: "md", md: "lg" }}
              rounded="md"
              color="black"
              bg="gray.100"
              _hover={{ bg: "blackAlpha.400", color: "white" }}
              px={8}
              py={3}
              cursor="pointer"
              target={"_blank"}
              rel="noopener noreferrer"
              href="https://billing.1-stream.com/whmcs/submitticket.php?step=2&deptid=5&subject=[Ref:Website%20Contact%20Form]%20"
            >
              Contact us now
            </chakra.a>
          </Box>
        </Stack>
      </Box>
      <Box w={{ base: "full", md: 10 / 12 }} mx="auto" textAlign="center">
        <Image
          w="full"
          rounded="lg"
          shadow="2xl"
          src={SupportImage}
          alt="Support"
        />
      </Box>
    </Flex>
  )
}

export default Hero
