import React, { useState, useEffect } from "react"
import Fade from "react-reveal/Fade"
import {
  Spinner,
  Tabs,
  Tab,
  TabList,
  chakra,
  SimpleGrid,
  Box,
  HStack,
} from "@chakra-ui/react"
import moment from "moment"
import { CheckIcon, InfoOutlineIcon, WarningTwoIcon } from "@chakra-ui/icons"


const UpdatesList = () => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [latest, setLatest] = useState("v1.0.0");

  const fetchData = async () => {
    await fetch("https://billing.1-stream.com/downloads/latest.txt")
      .then(res => res.text())
      .then(json => {
        setLatest(json.trim())
      });

    await fetch("https://billing.1-stream.com/downloads/changelogs.json")
      .then(res => res.json())
      .then(json => {
        setData(json)
        setLoading(false)
      });
  }

  useEffect(() => {
    fetchData()
  }, [])

  const singleUpdate = ({ update }) => {
    return (
      <>
        <Fade bottom cascade>
          <Tabs
            variant="soft-rounded"
            colorScheme="blue"
            color={"black"}
            p={3}
            mb={5}
            align="end"
            className="w-full bg-gradient-to-b from-blue-50 to-blue-200 rounded-sm shadow-2xl"
            isLazy
            defaultIndex={1}
          >
            <TabList>
              <Tab isDisabled>
                <chakra.span
                  mr={5}
                  color="blue.600"
                  fontSize={26}
                  left={8}
                  position={"absolute"}
                  fontWeight={"black"}
                >
                  Version: {update.version}{" "}
                  {update.version === latest ? (
                    <chakra.span
                      fontSize={14}
                      backgroundColor={"green.600"}
                      paddingX={2}
                      paddingY={1}
                      color={"white"}
                      borderRadius={10}
                    >
                      Latest Release
                    </chakra.span>
                  ) : (
                    ""
                  )}

                  {update.rc && (
                    <chakra.span
                      fontSize={14}
                      backgroundColor={"yellow.400"}
                      paddingX={2}
                      paddingY={1}
                      color={"black"}
                      borderRadius={10}
                    >
                      RC
                    </chakra.span>
                  )}

                </chakra.span>
              </Tab>
              <Tab isDisabled>
                <chakra.span
                  fontWeight={"bold"}
                  fontSize={12}
                  color="green.500"
                >
                  {moment(update.date).format("DD MMMM YYYY")}
                </chakra.span>
              </Tab>
            </TabList>

            <SimpleGrid
              columns={[1, 2]}
              gap="24px"
              mx="auto"
              textAlign={"left"}
            >
              <Box px={4} py={8} rounded="md">
                <chakra.h1
                  mb={3}
                  fontSize={{ base: "lg", md: "xl" }}
                  fontWeight="bold"
                  lineHeight="shorter"
                  color="green.600"
                  _dark={{ color: "white" }}
                >
                  Changelog
                </chakra.h1>
                <p>
                  <chakra.ul>
                    {update.changelog && update.changelog.length > 0 ?
                      update.changelog.map((element, index) => (
                        <HStack>
                          <CheckIcon w={12} h={4} color={"blue.400"} />
                          <chakra.li key={index}>{element}</chakra.li>
                        </HStack>
                      ))
                      :
                      <HStack>
                          <WarningTwoIcon w={12} h={4} color={"gray.400"} />
                          <chakra.li color={"gray.500"}>There are no available changelogs on this update.</chakra.li>
                      </HStack>
                    }
                  </chakra.ul>
                </p>
              </Box>

              <Box px={4} py={8} rounded="md">
                <chakra.h1
                  mb={3}
                  fontSize={{ base: "lg", md: "xl" }}
                  fontWeight="bold"
                  lineHeight="shorter"
                  color="red.700"
                  _dark={{ color: "white" }}
                >
                  BugFixes
                </chakra.h1>
                <p>
                  <chakra.ul>
                    {update.bugfixes && update.bugfixes.length > 0 ?
                      update.bugfixes.map((element, index) => (
                        <HStack>
                          <InfoOutlineIcon w={12} h={4} color={"blue.400"} />
                          <chakra.li key={index}>{element}</chakra.li>
                        </HStack>
                      ))
                      :
                      <HStack>
                          <WarningTwoIcon w={12} h={4} color={"gray.400"} />
                          <chakra.li color={"gray.500"}>There are no available bugfixes on this update.</chakra.li>
                    </HStack>
                    }
                  </chakra.ul>
                </p>
              </Box>
            </SimpleGrid>
          </Tabs>
        </Fade>
      </>
    )
  }

  return (
    <>
      <div
        className="max-w-7xl mx-auto mt-10 text-center text-white min-h-screen"
        id="UpdatesList"
      >
        <Fade bottom cascade>
          <h1 className="text-5xl font-poppins font-bold xxs:text-xl sm:text-3xl lg:text-5xl mb-10">
            This is the updates List
          </h1>
        </Fade>

        {loading ? <Spinner /> : data.map(update => singleUpdate({ update }))}
      </div>
    </>
  )
}

export default UpdatesList
