import React from "react"
import Layout from "../components/layout"
import UpdatesList from "../components/Updates"
import Seo from "../components/seo"

const Updates = () => {
  return (
    <>
    <Seo title={"Updates List"} />
    <Layout>
        <UpdatesList />
      </Layout>
      </>
  )
}

export default Updates
