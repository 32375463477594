import React, { useState } from "react"
import {
  Text,
  Box,
  Flex,
  useColorModeValue,
  Image,
  HStack,
  Stack,
  chakra,
} from "@chakra-ui/react"
import Fade from "react-reveal/Fade"
import url from "../../../global-config"

const Screenshots = () => {
  const arrowStyles = {
    cursor: "pointer",
    pos: "absolute",
    top: "50%",
    w: "auto",
    mt: "-22px",
    p: "16px",
    color: "white",
    fontWeight: "bold",
    fontSize: "18px",
    transition: "0.6s ease",
    borderRadius: "0 3px 3px 0",
    userSelect: "none",
    _hover: {
      opacity: 0.8,
      bg: "black",
    },
  }

  const slides = [
    {
      img: "/assets/images/screenshots/screenshot_00.png",
    },
    {
      img: "/assets/images/screenshots/screenshot_01.png",
    },
    {
      img: "/assets/images/screenshots/screenshot_02.png",
    },
    {
      img: "/assets/images/screenshots/screenshot_04.png",
    },
    {
      img: "/assets/images/screenshots/screenshot_05.png",
    },
  ]

  const [currentSlide, setCurrentSlide] = useState(0)

  const slidesCount = slides.length

  const prevSlide = () => {
    setCurrentSlide(s => (s === 0 ? slidesCount - 1 : s - 1))
  }
  const nextSlide = () => {
    setCurrentSlide(s => (s === slidesCount - 1 ? 0 : s + 1))
  }
  const setSlide = slide => {
    setCurrentSlide(slide)
  }
  const carouselStyle = {
    transition: "all .5s",
    ml: `-${currentSlide * 100}%`,
  }

  return (
    <Flex
      w="full"
      p={10}
      mb={15}
      mt={20}
      direction={{ base: "column", md: "row" }}
      alignItems="center"
      justifyContent="center"
      id="screenshots"
    >
      <Fade>
        <Flex w={{ base: "full", md: "3xl" }} pos="relative" overflow="hidden">
          <Box pos="relative" overflow="hidden">
            <Box maxW="7xl" mx="auto">
              <Box
                pos="relative"
                pb={{ base: 8, sm: 16, md: 20, lg: 28, xl: 32 }}
                w="full"
                border="solid 1px transparent"
              >
                <Box
                  maxW={{ base: "7xl" }}
                  px={{ base: 4, sm: 6, lg: 8 }}
                  mt={{ base: 12, md: 16, lg: 20, xl: 28 }}
                >
                  <Box
                    textAlign="center"
                    w={{ base: "full", md: 11 / 12, xl: 8 / 12 }}
                    mx="auto"
                  >
                    <chakra.h1
                      fontSize={{ base: "4xl", sm: "5xl", md: "6xl" }}
                      letterSpacing="tight"
                      lineHeight="short"
                      fontWeight="extrabold"
                      color={useColorModeValue("gray.300")}
                    >
                      <chakra.span display={{ base: "block", xl: "inline" }}>
                        Start Exploring Our{" "}
                      </chakra.span>
                      <chakra.span
                        display={{ base: "block", xl: "inline" }}
                        color={useColorModeValue("gray.100")}
                      >
                        Screenshots
                      </chakra.span>
                    </chakra.h1>
                    <chakra.p
                      mt={{ base: 3, sm: 5, md: 5 }}
                      mx={{ sm: "auto", lg: 0 }}
                      mb={6}
                      fontSize={{ base: "lg", md: "xl" }}
                      color={"gray.200"}
                      lineHeight="base"
                    >
                      Here are some of our software screenshots. There is a
                      large range of function that our software offers.
                    </chakra.p>
                    <Stack
                      direction={{ base: "column", sm: "column", md: "row" }}
                      mb={{ base: 4, md: 8 }}
                      spacing={{ base: 4, md: 2 }}
                      justifyContent="center"
                    >
                      <Box rounded="full" shadow="md">
                        <chakra.a
                          w="full"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          border="solid 1px transparent"
                          fontSize={{ base: "md", md: "lg" }}
                          rounded="md"
                          color="black"
                          bg="gray.100"
                          _hover={{ bg: "blackAlpha.400", color: "white" }}
                          px={{ base: 8, md: 10 }}
                          py={{ base: 3, md: 4 }}
                          cursor="pointer"
                          href={url.store}
                        >
                          Get started
                        </chakra.a>
                      </Box>
                    </Stack>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Flex>
        <Flex w={{ base: "full", sm: "lg", lg: "2xl" }} pos="relative" overflow="hidden">
          <Flex h="20%" w="full" {...carouselStyle}>
            {slides.map((slide, sid) => (
              <Box key={`slide-${sid}`} boxSize="full" shadow="md" flex="none">
                <Text
                  color="white"
                  fontSize="xs"
                  p="8px 12px"
                  pos="absolute"
                  top="0"
                >
                  {sid + 1} / {slidesCount}
                </Text>
                <Image
                  src={slide.img}
                  alt="carousel image"
                  boxSize="full"
                  backgroundSize="cover"
                  //style={{ backgroundImage: `url(${slide.img})`, backgroundSize: "fit" }}
                />
                <Stack
                  p="8px 12px"
                  pos="absolute"
                  bottom="24px"
                  textAlign="center"
                  w="full"
                  mb="8"
                  color="white"
                >
                  <Text fontSize="2xl">{slide.label}</Text>
                  <Text fontSize="lg">{slide.description}</Text>
                </Stack>
              </Box>
            ))}
          </Flex>
          <Text {...arrowStyles} left="0" onClick={prevSlide}>
            &#10094;
          </Text>
          <Text {...arrowStyles} right="0" onClick={nextSlide}>
            &#10095;
          </Text>
          <HStack justify="center" pos="absolute" bottom="8px" w="full">
            {Array.from({ length: slidesCount }).map((_, slide) => (
              <Box
                key={`dots-${slide}`}
                cursor="pointer"
                boxSize={["7px", "15px"]}
                m="0 2px"
                bg={
                  currentSlide === slide ? "blackAlpha.800" : "blackAlpha.500"
                }
                rounded="50%"
                display="inline-block"
                transition="background-color 0.6s ease"
                _hover={{ bg: "blackAlpha.800" }}
                onClick={() => setSlide(slide)}
              ></Box>
            ))}
          </HStack>
        </Flex>
      </Fade>
    </Flex>
  )
}
export default Screenshots
