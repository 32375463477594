import React from "react";
import {
  chakra,
  Box,
  SimpleGrid,
  Flex,
  useColorModeValue,
  Icon,
} from "@chakra-ui/react";

import { FeaturesData } from "../../data/features";


export default function Features() {
  const Feature = (props) => {
    return (
      <Box>
        <Flex
          alignItems="center"
          justifyContent="center"
          w={16}
          h={16}
          mb={4}
          rounded="full"
          color={useColorModeValue(`${props.color}.600`, `${props.color}.100`)}
          bg={useColorModeValue(`${props.color}.100`, `${props.color}.600`)}
        >
          <Icon
            boxSize={10}
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            {props.icon}
          </Icon>
        </Flex>
        <chakra.h2
          mb={2}
          fontWeight="semibold"
          lineHeight="shorter"
          color={useColorModeValue("gray.100")}
          fontSize={22}
        >
          {props.title}
        </chakra.h2>
        <chakra.p
          fontSize="sm"
          color={"gray.200"}
        >
          {props.children}
        </chakra.p>
      </Box>
    );
  };
  return (
    <Flex
      //bg={useColorModeValue("#F9FAFB", "gray.600")}
      p={{ base: 0, md: 20, lg: 25 }}
      w="auto"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        px={8}
        py={20}
        mx="auto"
        //bg={useColorModeValue("white", "gray.800")}
        //shadow="xl"
        id="features"

      >
        <Box textAlign={{ lg: "center" }}>
          <chakra.p
            mt={2}
            fontSize={{ base: "3xl", sm: "4xl" }}
            lineHeight="8"
            fontWeight="extrabold"
            letterSpacing="tight"
            color={useColorModeValue("gray.100")}
          >
            Features
          </chakra.p>
          <chakra.p
            mt={4}
            maxW="2xl"
            fontSize="xl"
            mx={{ lg: "auto" }}
            color={useColorModeValue("gray.300", "gray.400")}
          >
            Get insights to dig down into what&apos;s powering your growth the
            most.
          </chakra.p>
        </Box>
        <SimpleGrid
          columns={{ base: 2, sm: 2, md: 3, lg: 4 }}
          spacingX={{ base: 5, sm: 16, lg: 24 }}
          spacingY={20}
          mt={6}
        >
          {FeaturesData.map(feature => (
            <Feature
              color={feature.icon_color}
              title={feature.title}
              icon={
                <path
                  fillRule="evenodd"
                  d={feature.icon}
                  clipRule="evenodd"
                />
              }
            >
              {feature.description}
            </Feature>
          ))}

         
        </SimpleGrid>
      </Box>
    </Flex>
  );
}