import * as React from "react"
import CookieConsent from "react-cookie-consent"

export default function Cookie () {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept All Cookies"
      cookieName="use_official_cookies"
      cookiePolicyName="Cookie Policy"
      style={{ background: "#1d3a91" }}
      buttonStyle={{
        color: "#000",
        background: "#fff",
        borderRadius: 10,
        padding: 12,
        fontSize: "16px",
      }}
      expires={150}
      declineButtonText="Don't Accept"
      overlay={true}
      buttonClasses="btn"
      flipButtons={true}
    >
      We use cookies and collect data to gain insights into how our website is used, allowing us to customize the content to better suit your needs. 
      <br />
      For further details about the various types of cookies we use and our GDPR-compliant data collection practices, please refer to our{" "}
      <a
        href="https://billing.1-stream.com/whmcs/index.php/knowledgebase/3/Privacy-policy.html"
        target={"_blank"}
        rel="noopener noreferrer"
        style={{ borderBottom: "1px solid", opacity: ".9" }}
      >
        Privacy Statement
      </a>
      .
    </CookieConsent>
  )
}
