import React from "react"
import Layout from "../components/layout"
import Hero from "../components/Support/Hero"
import Faq from "../components/Support/Faq"
//import Newsletter from "../components/Newsletter"
import Seo from "../components/seo"

const Support = () => {
  return (
    <>
      <Seo title={"Help & Support"} />
      <Layout>
        <Hero />
        <Faq />
        {/* <Newsletter /> */}
      </Layout>
    </>
  )
}

export default Support
