import React from "react"
import Layout from "../components/layout"
import Hero from "../components/Software/Hero"
import Features from "../components/Software/Features"
import Pricing from "../components/Software/Pricing"
import Screenshots from "../components/Software/Screenshots"
import Licenses from "../components/Software/Licenses"
import Tutorials from "../components/Software/Tutorials"
import Seo from "../components/seo"

const Software = () => {
  return (
    <>
      <Seo title={"Software Details"} description={"Our software is 100% self-developed from scratch. New ideas and hard work are what make our project genuine! And we don't stop here. Share your ideas with our team, we will be happy to incorporate them into our future updates and make this journey in the streaming world together. After all, everything will be based on your needs. We are here to make streaming easier and safer for you."}  />
      <Layout>
        <Hero />
        <Features />
        <Pricing />
        <Licenses />
        <Screenshots />
        <Tutorials />
      </Layout>
    </>
  )
}

export default Software
