export const faqData = [
  {
    title: "How can I buy the software?",
    description: "You can get the software from the following link:",
    url: "https://billing.1-stream.com/whmcs/",
  },
  {
    title: "How can I open a ticket?",
    description:
      "You can open a ticket here:",
    url: "https://billing.1-stream.com/whmcs/submitticket.php",
  },
  {
    title: "How to install the software on my server?",
    description:
      "Installation guide:",
    url: "https://billing.1-stream.com/whmcs/index.php/knowledgebase/2/Installation.html",
  },
  {
    title: "How can I migrate the data from another software?",
    description:
      "Migration guide:",
    url: "https://billing.1-stream.com/whmcs/index.php/knowledgebase/5/Migration-Guide-Experimental.html",
  },
  {
    title: "Do you offer any trial licenses?",
    description:
      "Yes, you can get the software from the following link:",
    url: "https://billing.1-stream.com/whmcs/index.php/store/streaming-platform/software-1-stream-core-platform-5-days-trail-account",
  },
  {
    title: "Do you have a demo website?",
    description: "For security reasons currently we don't offer a demo website.",
    url: "",
  },
]
