import * as React from "react";
import { Box, Heading, Grid, Image, Badge, GridItem, Text, List, ListItem, ListIcon, Fade } from "@chakra-ui/react";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

const color = {
    "outdated": "red",
    "planned": "blue",
    "supported": "green"
};

const SupportedList = ({ list }) => {
    return (
        <Box mt="3">
            <Heading size="sm">Supports: </Heading>
            <List spacing="-1" fontSize="xs" fontWeight="bold">
                {list.map((el, index) => (
                    <ListItem textTransform="capitalize" key={"supported-list" + el + index}>
                        <ListIcon as={IoMdCheckmarkCircleOutline} color='green.500' />
                        {el.replace(/_/g, " ")}
                    </ListItem>
                ))}
            </List>
        </Box>
    )
}

export const ModelCard = ({ name, description, image, status, note, supports }) => {

    return (
        <Fade in={true}>
            <Box bg={"whiteAlpha.800"} p="4" borderRadius="3xl">
                <Grid
                    templateColumns='repeat(6, 1fr)'
                    gap={4}
                >

                    <GridItem rowSpan={2} colSpan={2}>
                        <Image src={image} borderRadius="xl" backgroundColor="white" />
                    </GridItem>
                    <GridItem colSpan={4}>


                        <Heading size="sm">
                            {name}
                            <Badge ml="2" colorScheme={color[status]} fontSize="2xs" variant='subtle'>{status}</Badge>
                        </Heading>
                        <Text fontSize="xs" color="gray.600">
                            {description}
                        </Text>

                        {supports && (<SupportedList list={supports} />)}

                        {note && (
                            <Text color="red.600" fontSize="xs" fontWeight="light" mt="2">
                                **{note}
                            </Text>
                        )}
                    </GridItem>
                </Grid>
            </Box>
        </Fade>
    )
}