import React from "react"
import Input from "../Atoms/input"
import Button from "../Atoms/button"
import Fade from "react-reveal/Fade"

const BlogsContainer = () => {
  const blogData = [
    {
      img:
        "https://upload.wikimedia.org/wikipedia/commons/a/a8/Town_of_Pomorie_aerial_Boby_Dimitrov.jpg",
      heading: "Meet Us at Pomorie(organised by SEC), 8-10 June",
      para: "Pomorie, historically known as Anchialos, is a town and seaside resort in southeastern Bulgaria, located on a narrow rocky peninsula in Burgas Bay on the southern Bulgarian Black Sea Coast.",
    },
    {
      img:
        "https://www.ekinops.com/images/events/ekinops_angacom_vignette.png",
      heading: "Meet Us at ANGACOM 2023, 23-25 May",
      para: "ANGA COM is Europe’s leading business platform for Broadband, Television and Online. It brings together network operators, vendors and content providers on all issues of broadband and media distribution. The next show date is 23 to 25 May 2023 in Cologne/Germany.",
    },
    {
      img:
        "https://comingsoon.ae/wp-content/uploads/2023/02/CABSAT-Banner_1024x1024.jpg",
      heading: "Meet Us at CABSAT 2023, 16-18 May",
      para: "Middle East & Africa’s leading event for content, streaming, satellite, media & entertainment industry professionals looking to leverage the latest technology and innovation in creating the next-gen content",
    },
  ]
  return (
    <div className="max-w-7xl mx-auto mt-10 text-white mb-10">
      <Fade bottom cascade>
        <div className="grid grid-cols-3 gap-4 xxs:grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
          {blogData.map(blog => (
            <div>
              <div className="w-96 h-72 overflow-hidden rounded-xl xxs:w-full sm:w-96">
                <img
                  className="h-full w-full object-cover"
                  src={blog.img}
                  alt=""
                ></img>
              </div>
              <h1 className="text-3xl mt-2 font-poppins font-bold">
                {blog.heading}
              </h1>
              <p className="text-sm opacity-50 mt-2">{blog.para}</p>
            </div>
          ))}
        </div>
      </Fade>
      {/* <div className="w-full h-72 mt-10 bg-gradient-to-r from-pink to-purple rounded-xl flex flex-col items-center justify-center">
        <h1 className="font-poppins font-bold text-3xl">Stay in the loop</h1>
        <h2 className="text-lg mt-2 opacity-50">
          Join our newsletter to get top news before anyone else
        </h2>
        <div className="flex-row mt-10">
          <Input placeholder="email."></Input>
          <Button
            colorClass="bg-white"
            title="Subscribe"
            textColor="text-black"
            marginClass="ml-5"
          ></Button>
        </div>
      </div> */}
    </div>
  )
}

export default BlogsContainer
