import React from "react"
import Fade from "react-reveal/Fade"

const BlogHeader = () => {
  return (
    <Fade bottom>
      <div className="max-w-7xl mx-auto mt-10 flex text-white xxs:flex-col sm:flex-row">
        <div className="h-full object-cover overflow-hidden rounded-xl m-5">
          <img src="https://www.chasingthedonkey.com/wp-content/uploads/2019/04/View-of-Plovdiv-Bulgaria_Depositphotos_175773698_l-2015.jpg" alt=""></img>
        </div>
        <div className="m-5">
          <h2 className="text-sm opacity-50">Headquarters</h2>
          <h1 className="text-5xl font-bold font-poppins xxs:text-lg sm:text-5xl">
            Where the magic happens! Meet Us and discuss more...
          </h1>
          <p className="text-lg mt-4 opacity-50 xxs:text-sm sm:text-lg">
            Plovdiv is an ancient city built around 7 hills, in southern Bulgaria. The Regional Archaeological Museum chronicles the city’s history, with exhibits including mosaic panels, clay lamps and early coins.
          </p>
        </div>
      </div>
    </Fade>
  )
}

export default BlogHeader
