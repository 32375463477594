import * as React from "react";
import {
    Box, Divider, Heading, Container, Grid, Flex, GridItem, Center, RadioGroup, Stack, Radio, Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
} from "@chakra-ui/react";
import { ModelCard } from "./model-card";

export const ListDevices = () => {

    const fetchUrl = "https://billing.1-stream.com/downloads/compatible-devices.json";
    const [data, setData] = React.useState([]);
    const [filteredSupported, setSupportedFilter] = React.useState('');

    const fetchData = async () => {
        await fetch(fetchUrl)
            .then(res => res.json())
            .then(res => {
                setData(res);
            });
    };

    const supportTypes = () => {
        let types = new Set();

        data.map((row) => {
            row?.models.map((model) => { model.supports?.forEach(types.add, types) });
        })

        return ([...types])
    }


    const RowUi = ({ name, description, logo, models }) => {
        return (
            <Box position='relative' padding='10'>

                <Grid
                    templateColumns='repeat(9, 1fr)'
                    gap={4}
                >

                    <GridItem colSpan={{ base: 9, lg: 1}}>
                        <img src={logo} />
                    </GridItem>
                    <GridItem colSpan={{ base: 9, lg: 8 }}>

                        <Heading color="white" size="lg">
                            {name}
                        </Heading>

                        <Heading color="white" size="sm" fontWeight="light">
                            {description}
                        </Heading>
                    </GridItem>
                </Grid>
                <Divider my="4" />

                <Grid
                    templateColumns={{ base: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)', lg: 'repeat(4, 1fr)', xl: 'repeat(6, 1fr)'}}
                    gap={4}
                >
                    <FilteredModels models={models} />
                </Grid>

            </Box>
        )
    }

    React.useEffect(() => {
        fetchData();
    }, []);

    const FilteredRows = () => {

        var results = data.filter((row) => row.models.length > 0);

        return results.map((row, index) => <RowUi key={row.name + index} {...row} />);
    }

    const FilteredModels = ({ models }) => {

        var results = models;

        if (filteredSupported !== "") {
            results = results.filter((res) => res.supports?.includes(filteredSupported));
        }


        return results?.map((model) => <GridItem key={model.name} colSpan={1}><ModelCard {...model} /></GridItem>)

    }

    return (
        <Container maxW='container.4xl'>
            <Box textAlign="center" my="24">
                <Flex direction={{ base: "column", md: "row"}}>
                    <Box width="full">
                        <Heading color="white" size="4xl">
                            Compatible Devices
                        </Heading>
                    </Box>
                    <Box width="full" px={{ base: "0", md: "32"}} mt="8">

                        <Alert status='success' borderRadius="xl" flexDirection='column' textAlign="left">
                            <Flex>
                                <AlertIcon boxSize='36px' />
                                <div>
                                    <AlertTitle>We are continually adding and updating supported devices on the page,so please don't worry if some is currently unavailable.</AlertTitle>
                                    <AlertDescription>We are ensuring that everything is set up smoothly.</AlertDescription>
                                </div>
                            </Flex>

                        </Alert>
                    </Box>

                </Flex>
            </Box>

            <Center>

                <RadioGroup onChange={setSupportedFilter} value={filteredSupported}>
                    <Stack direction={{ base: 'column', md: "row" }} color="white">
                        <Radio value=''>All</Radio>
                        {
                            supportTypes().map((type, index) => <Radio key={type + index} value={type}>{type.replace(/_/g, " ").toUpperCase()}</Radio>)
                        }
                    </Stack>
                </RadioGroup>
            </Center>

            <FilteredRows />
        </Container>
    )
}