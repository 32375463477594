import * as React from "react"
import Layout from "../components/layout"

// Components
import Header from "../components/Home/header"
import Pricing from "../components/Software/Pricing"
import { ChakraProvider } from "@chakra-ui/react"
import Cookie from "../components/Cookie"
import Seo from "../components/seo"
//import Newsletter from "../components/Newsletter"
import Licenses from "../components/Software/Licenses"

const IndexPage = () => (
  <>
    <Seo title={"Get Started"} />
      <Layout>
        <ChakraProvider>
          <Header></Header>
        <Pricing></Pricing>
        <Licenses />
        {/* <Newsletter /> */}
        </ChakraProvider>
        <Cookie />
      </Layout>
  </>
)

export default IndexPage
